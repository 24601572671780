<template>
  <v-container fluid>
    <TableViewComponent
      title="Table transmissions > écosystèmes"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter un écosystème"
      itemLabel="écosystème"
      itemsLabel="écosystèmes"
      :items="items"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cet écosystème?"
      :pagination="false"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { EcoSystemsService } from "@/service/conf/transmission/ecosystems_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


export default {
  name: "TableEcoSystems",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les éléments */
      items: [],

      /**le service d'accès à l'api */
      service: null,
    };
  },
  methods: {
    onAddElement() {
      this.$router.push(routes.transmission.ecosystems.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.items = [];

        let items = await this.service.getAll();

        items.forEach((r) => {
          let item = JSON.parse(JSON.stringify(r));

          //action de consultation
          item.view = () => {
            this.$router.push(
              routes.transmission.ecosystems.view.root + "/" + item.id
            );
          };

          //action de modification
          item.edit = () => {
            this.$router.push(
              routes.transmission.ecosystems.edit.root + "/" + item.id
            );
          };

          //action de suppression
          item.delete = async () => {
            try {
              await this.service.delete(item.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "problème lors de la suppression : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.items.push(item);

          //   console.log(JSON.stringify(this.items, null, 4));
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "problème lors du chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Libellé",
          align: "start",
          sortable: true,
          value: "label",
          default: true,
        },
        {
          text: "Définition",
          align: "start",
          sortable: true,
          value: "definition",
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditConf];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditConf, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadConf];
    },
  },
  mounted() {
    //instanciation du service d'accès à l'api 'écosystème' dans la partir 'transmission'.
    this.service = new EcoSystemsService(
      this.$api.getTransmissionEcoSystemsApi()
    );

    this.load();
  },
};
</script>

<style>
</style>